<template>
  <div class="inner">
    <div class="top">
      <div class="points-box">
        <div class="points-font">{{$t('index.total_votes')}}</div>
        <div class="num">{{allPoints}}</div>
      </div>
    </div>
    <votelist :voteMsg="voteMsg" :total="total" type="1" @fatherMea="getNoJoin"></votelist>
  </div>
</template>

<script>
import votelist from "@/components/voteList";
import { noVoteAccountList } from "@/api/detail";
export default {
  components: {
    votelist,
  },
  data() {
    return {
      voteMsg: [
      ],
      total:0,
      allPoints:0
    };
  },
  methods: {
   async getNoJoin(){
      let obj = {};
      obj.id = this.$route.query.id;
      let res = await noVoteAccountList(obj);
      this.total = res.total;
      this.allPoints = res.total_vote_number;
      this.voteMsg.push(...res.data);
    }
  },
};
</script>

<style lang="less" scoped>
.inner {
  .top {
    width: 100%;
    height: 97px;
    background-color: #fff;
    overflow: hidden;
    .points-box {
      margin-top: 20px;
      margin-left: 5.6%;
      .points-font {
        font-size: 12px;
        color: #777789;
      }
      .num {
        font-size: 30px;
        font-weight: bold;
        color: #181a33;
        margin-top: 10px;
      }
    }
  }
}
</style>